import revive_payload_client_WQTtlhIppk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_j7jCg8o5Lo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fM49ZQwmBf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Viuxoh1f8e from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_7OKFW8esAC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_8PzcKQ0xvO from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.7.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_TGvexRygla from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_MKrUsfoHWb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cleave_HD9soKWDBf from "/opt/build/repo/plugins/cleave.ts";
import date_picker_client_l6L1nahZSs from "/opt/build/repo/plugins/date-picker.client.ts";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import bugsnag_client_TtQ636hzGt from "/opt/build/repo/plugins/bugsnag.client.ts";
import click_outside_D2NiLGp7dJ from "/opt/build/repo/plugins/click-outside.ts";
import error_handler_client_sQhJOBiqXf from "/opt/build/repo/plugins/error-handler.client.ts";
import eventBus_P6CbRuQa3W from "/opt/build/repo/plugins/eventBus.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_WQTtlhIppk,
  unhead_j7jCg8o5Lo,
  router_fM49ZQwmBf,
  payload_client_Viuxoh1f8e,
  check_outdated_build_client_7OKFW8esAC,
  plugin_vue3_8PzcKQ0xvO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TGvexRygla,
  chunk_reload_client_MKrUsfoHWb,
  cleave_HD9soKWDBf,
  date_picker_client_l6L1nahZSs,
  axios_QMFgzss1s4,
  bugsnag_client_TtQ636hzGt,
  click_outside_D2NiLGp7dJ,
  error_handler_client_sQhJOBiqXf,
  eventBus_P6CbRuQa3W,
  vue_query_wrmMkNpEpe
]