import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/build/repo/middleware/auth.global.ts";
import check_45if_45onboarded_45global from "/opt/build/repo/middleware/checkIfOnboarded.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@types+node@22.10.6_eslint@8.57.1_rollup@2.79.2_stylelint@16.13.1_typescript@5.7.3_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  check_45if_45onboarded_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}