<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue'
import SuccessJSON from '@/assets/lottie-files/success.json'
import { toast } from '~/components/ui/toast'

interface Emits {
  (e: 'close'): void
}

const emit = defineEmits<Emits>()

function close() {
  toast({
    title: 'Direct debit connection successful',
    description: 'Your direct debit has been setup successfully',
  })

  emit('close')
}
</script>

<template>
  <div>
    <div class="flex h-36 items-center justify-center rounded-lg">
      <LottieAnimation :animation-data="SuccessJSON" class="h-full" loop />
    </div>
    <div class="mt-4 text-center">
      <h2 class="text-xl font-bold text-primary">Connection successful</h2>
      <p class="mt-1 text-sm text-gray-500 text-primary">
        Your mandate has been connected!
      </p>
      <Button class="mt-5 w-full max-w-xs" @click="close">Close</Button>
    </div>
  </div>
</template>
