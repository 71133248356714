import { LOCAL_STORAGE_KEYS } from '~/constants/CONSTANTS'
import type { GetMeResponseV2 } from '~/types/apiResponse/me.response'

export function useUserFlow() {
  const profileStore = useProfileStore()
  const router = useRouter()

  const utils = {
    getOrganisationId(from: 'localstorage' | 'store') {
      if (from === 'localstorage') {
        return localStorage.getItem(
          LOCAL_STORAGE_KEYS.LAST_SELECTED_ORGANISATION_ID,
        )
      } else {
        const { organisationId } = storeToRefs(profileStore)
        return organisationId.value
      }
    },

    setOrganisationId(
      organisationId: string,
      where: 'localstorage' | 'store' | 'both' = 'both',
    ) {
      if (where === 'store' || where === 'both') {
        profileStore.setOrgId(organisationId)
      }

      if (where === 'localstorage' || where === 'both') {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.LAST_SELECTED_ORGANISATION_ID,
          organisationId,
        )
      }
    },

    setProfile(data: GetMeResponseV2) {
      profileStore.$patch((state) => {
        state.profile = data
      })
    },

    getOrganisationByIdFromOrganisationsList(
      organisations: GetMeResponseV2['organisations'],
      organisationId: string,
    ) {
      return organisations.find((org) => org.id === organisationId)
    },
  }

  async function fetchOrganisations() {
    const { $api } = useNuxtApp()
    const { data } = await $api.composition.me.getMeV2()

    return data
  }

  async function initProfileAndOrganisation() {
    const { $api } = useNuxtApp()

    try {
      const { data, status } = await $api.composition.me.getMeV2()

      if (status !== 200) {
        if (status === 404) {
          return router.replace(routes.profile)
        } else {
          throw new Error('Failed to fetch user data.')
        }
      }

      utils.setProfile(data)

      const organisations = data.organisations

      const LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEYS.LAST_SELECTED_ORGANISATION_ID

      if (organisations.length === 0) {
        // If we don't have any organisation, then we need to go to onboarding
        router.push('/onboarding')
        return
      }

      if (organisations.length === 1) {
        // Just one organisation, set it.
        const { id } = organisations[0]
        utils.setOrganisationId(id)
        await profileStore.setOrganisation()
      } else {
        const lastUsedOrgId = localStorage.getItem(LOCAL_STORAGE_KEY)
        const isValid = organisations.some((org) => org.id === lastUsedOrgId)

        if (isValid) {
          // If we check our list of organisation and the last used and it matches any, set it and move on
          utils.setOrganisationId(lastUsedOrgId!)
          await profileStore.setOrganisation()
        } else {
          // TODO: Redirect the user to select an organization
          // Example: router.push('/select-organisation');
          return navigateTo('/user/invitations')
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return router.replace(routes.profile)
      }
      // console.error(error.message)
      // Optionally, handle the error by showing a notification to the user
      // Example: notifyUser(error.message);
    }
  }

  return {
    utils,
    fetchOrganisations,
    initProfileAndOrganisation,
  }
}
