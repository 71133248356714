<template>
  <div class="mx-auto w-[432px] rounded-lg border bg-white p-8">
    <DialogTitle
      as="h2"
      class="pb-7 text-center text-xl font-bold text-[#132248]"
    >
      Invite a member
    </DialogTitle>
    <form @submit.prevent="onSubmit">
      <div class="grid gap-x-5 gap-y-[27px] sm:grid-cols-2">
        <div>
          <Label
            for="first_name"
            class="mb-1 text-sm font-medium text-[#132248]"
            >First name</Label
          >
          <Input
            id="first_name"
            v-model="v$.first_name.$model"
            type="text"
            placeholder="Enter your first name"
          />

          <span class="text-sm text-destructive">{{
            vuelidateErrorMessage(v$.first_name.$errors)
          }}</span>
        </div>

        <div>
          <Label for="last_name" class="mb-1 text-sm font-medium text-[#132248]"
            >Last name</Label
          >
          <Input
            id="last_name"
            v-model="v$.last_name.$model"
            type="text"
            placeholder="Enter your last name"
          />

          <span class="text-sm text-destructive">{{
            vuelidateErrorMessage(v$.last_name.$errors)
          }}</span>
        </div>

        <div class="col-span-full">
          <Label for="email" class="mb-1 text-sm font-medium text-[#132248]"
            >Email</Label
          >
          <Input
            id="email"
            v-model="v$.email.$model"
            type="text"
            placeholder="Enter your email"
          />

          <span class="text-sm text-destructive">{{
            vuelidateErrorMessage(v$.email.$errors)
          }}</span>
        </div>
        <div class="col-span-full">
          <Label for="role" class="mb-1 text-sm font-medium text-[#132248]"
            >Member Role</Label
          >
          <Select id="role" v-model="v$.role.$model" class="max-w-full">
            <SelectTrigger class="h-10 w-full">
              <SelectValue placeholder="Select a role">
                {{ form.role }}
              </SelectValue>
            </SelectTrigger>
            <SelectContent side="bottom" class="z-[5000000000] break-all">
              <template v-if="roles">
                <SelectItem
                  v-for="role in roles"
                  :key="role.name"
                  :value="role.name"
                  class="max-w-full cursor-pointer text-primary"
                >
                  <div class="max-w-xs">
                    <p class="font-medium">{{ role.name }}</p>
                    <p>{{ role.description }}</p>
                  </div>
                </SelectItem>
              </template>
            </SelectContent>
          </Select>

          <span class="text-sm text-destructive">{{
            vuelidateErrorMessage(v$.role.$errors)
          }}</span>
        </div>

        <div
          v-if="
            canHaveAdditionalPermissions.approval ||
            canHaveAdditionalPermissions.payroll
          "
          class="col-span-full border-b pb-4 text-sm"
        >
          <p class="text-base font-medium text-primary">
            Additional permissions
          </p>
          <div class="mt-4 space-y-4">
            <div
              v-if="canHaveAdditionalPermissions.approval"
              class="flex gap-2"
            >
              <BaseCheckBox
                v-model="form.permission_ids"
                :value="additionPermissions['Bills.ApproveBills']"
              />
              <div class="">
                <p class="font-bold text-primary">Approver permission</p>
                <p class="font-normal text-[#374151]">
                  User will have access to approve/decline bills
                </p>
              </div>
            </div>

            <div v-if="canHaveAdditionalPermissions.payroll" class="flex gap-2">
              <BaseCheckBox
                v-model="form.permission_ids"
                :value="additionPermissions.Payroll"
              />
              <div class="">
                <p class="font-bold text-primary">Payroll permission</p>
                <p class="font-normal text-[#374151]">
                  User will have access to company's payroll details
                </p>
              </div>
            </div>
          </div>
        </div>

        <template
          v-if="
            v$.first_name.$model && v$.last_name.$model && needToAcceptTerms
          "
        >
          <div class="col-span-full text-xs">
            <div class="flex gap-1">
              <label for="terms" class="font-medium text-primary">
                <input
                  id="terms"
                  v-model="v$.acceptedTerms.$model"
                  class="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-primary checked:bg-primary focus:outline-none"
                  type="checkbox"
                />
              </label>
              <ModulesSettingsTeamRoleConfirmationMessage
                :role="v$.role.$model.toLowerCase()"
                :full-name="v$.first_name.$model + ' ' + v$.last_name.$model"
              />
            </div>
            <span
              v-if="v$.acceptedTerms.$error"
              class="mt-1 block text-destructive"
              >{{ vuelidateErrorMessage(v$.acceptedTerms.$errors) }}</span
            >
          </div>
        </template>
        <Button
          :disabled="isPending || v$.$invalid"
          variant="default"
          class="col-span-full w-full"
        >
          <ph-spinner v-if="isPending" :size="16" class="mr-3 animate-spin" />
          Invite member</Button
        >
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { PhSpinner } from '@phosphor-icons/vue'
import { useMutation } from '@tanstack/vue-query'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import { ref } from 'vue'

import axios from 'axios'
import { Button } from '@/components/ui/button'
import { DialogTitle } from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useToast } from '@/components/ui/toast/use-toast'
import { setModalState } from '@/services/modal'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profile'
import { mustBeTrue } from '~/lib/customValidators'
import { vuelidateErrorMessage } from '~/lib/utils'
import { getRolesQuery } from '~/queries/roles.query'
import type { SendInvitationPayload } from '~/types/apiPayload/invitations.payload'
import type { PermissionsIds } from '~/types/models/roles.model'

const authStore = useAuthStore()
const profileStore = useProfileStore()
const { toast } = useToast()
const { personId } = storeToRefs(authStore)
const { organisationId, currentlySelectedOrganisation } =
  storeToRefs(profileStore)

const { data: theRoles } = getRolesQuery()

const roles = computed(() => {
  if (!theRoles.value) return []
  const desiredOrder = ['Administrator', 'Payer', 'Creator', 'Reader']
  return [...theRoles.value].sort((a, b) => {
    if (desiredOrder.indexOf(a.name) > desiredOrder.indexOf(b.name)) return 1
    else return -1
  })
})

const form = ref({
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  acceptedTerms: false,
  permission_ids: [] as PermissionsIds[number][],
})

const needToAcceptTerms = computed(() => {
  const role = form.value.role.toLowerCase()
  return role === 'administrator' || role === 'payer'
})

const canHaveAdditionalPermissions = computed(() => {
  const roleName = form.value.role.toLowerCase()

  return {
    payroll: ['payer'].includes(roleName),
    approval: ['payer', 'creator'].includes(roleName),
  }
})

type PermissionsMap = {
  [K in PermissionsIds[number]]?: K
}

const additionPermissions = {
  Payroll: 'Payroll',
  'Bills.ApproveBills': 'Bills.ApproveBills',
} satisfies PermissionsMap

const mustBeTrueIfNeedToAcceptTerms = (param: ComputedRef<boolean>) =>
  helpers.withParams({ type: 'mustBeTrue', value: param }, (value: boolean) => {
    if (!param.value) {
      return true // If param is false, return true immediately
    } else {
      return mustBeTrue(value) // Otherwise, delegate to mustBeTrue validator
    }
  })

const rules = {
  first_name: {
    required: helpers.withMessage('First name is required.', required),
  },
  last_name: {
    required: helpers.withMessage('Last name is required.', required),
  },
  email: {
    required: helpers.withMessage('Email is required', required),
    isEmail: helpers.withMessage('Invalid email.', email),
  },
  role: {
    required: helpers.withMessage('Please pick a role', required),
  },
  acceptedTerms: {
    mustBeTrue: helpers.withMessage(
      'You must accept terms to proceed',
      mustBeTrueIfNeedToAcceptTerms(needToAcceptTerms),
    ),
  },
}

const { $api, $event } = useNuxtApp()

const v$ = useVuelidate(rules, form)

const { mutate, isPending } = useMutation({
  mutationFn: $api.core.invitations.sendInvitation,
  onSuccess(_, variables) {
    $event('track:mixpanel', {
      event: 'Invited a member',
      data: {
        ...variables,
        organisationId: organisationId.value,
        company_name: currentlySelectedOrganisation.value?.registered_name,
      },
    })
    $event('fetch:team-members', null)
    setModalState({ isOpen: false, type: 'inviteMembers' })
    toast({
      title: 'Successful',
      description: 'The invitation has been sent successfully',
      variant: 'default',
    })
  },

  onError(error) {
    let errorMessage: string | undefined

    if (axios.isAxiosError(error)) {
      function getFirstFailureReason(
        failureReasons: Record<string, string[]>,
      ): string | undefined {
        for (const key in failureReasons) {
          if (failureReasons[key] && failureReasons[key].length > 0) {
            return failureReasons[key][0]
          }
        }
        return undefined
      }

      errorMessage = getFirstFailureReason(
        error.response?.data?.failure_reasons,
      )
    }

    toast({
      title: 'Error',
      description:
        errorMessage ||
        error?.response?.data?.detail ||
        'There was an error; please attempt the action again.',
      variant: 'destructive',
    })
  },
})

function onSubmit() {
  if (!personId.value) {
    return toast({
      title: 'Permission error',
      description: 'You are not an admin.',
      variant: 'destructive',
    })
  }

  v$.value.$touch()
  if (v$.value.$invalid) return

  const { email, first_name, last_name, role, permission_ids } = form.value

  const canHavePayrollPermissions = canHaveAdditionalPermissions.value.payroll
  const canHaveApproverPermissions = canHaveAdditionalPermissions.value.approval

  function removePermissionIfNotAllowed(
    permission: PermissionsIds[number],
    isAllowed: boolean,
  ) {
    if (!isAllowed) {
      const index = permission_ids.indexOf(permission)
      if (index !== -1) {
        permission_ids.splice(index, 1)
      }
    }
  }

  removePermissionIfNotAllowed('Payroll', canHavePayrollPermissions)
  removePermissionIfNotAllowed('Bills.ApproveBills', canHaveApproverPermissions)

  const payload: SendInvitationPayload = {
    email,
    firstname: first_name,
    lastname: last_name,
    organisation_id: organisationId.value!,
    inviter_person_id: personId.value,
    role,
    permission_ids: permission_ids.length ? permission_ids : null,
  }

  mutate(payload)
}
</script>
