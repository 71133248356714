<template>
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.3501 4C8.3501 2.20507 9.80517 0.75 11.6001 0.75H23.6001C23.6573 0.75 23.7122 0.772724 23.7526 0.813172L32.3869 9.44749C32.4274 9.48794 32.4501 9.5428 32.4501 9.6V28C32.4501 29.7949 30.995 31.25 29.2001 31.25H11.6001C9.80517 31.25 8.3501 29.7949 8.3501 28V4Z"
      fill="white"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <path
      d="M23.6001 0.399994V5.6C23.6001 7.80913 25.391 9.59999 27.6001 9.59999H32.8001"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <rect x="0.200195" y="12.8" width="26" height="16" rx="2" fill="#7F56D9" />
    <path
      d="M5.58967 17.5273V24.8H4.05202V17.5273H5.58967ZM6.85476 17.5273H8.75107L10.7539 22.4136H10.8391L12.842 17.5273H14.7383V24.8H13.2468V20.0663H13.1864L11.3043 24.7645H10.2887L8.40661 20.0486H8.34624V24.8H6.85476V17.5273ZM20.8276 19.8781C20.7779 19.7053 20.708 19.5526 20.6181 19.42C20.5281 19.2851 20.418 19.1715 20.2878 19.0791C20.16 18.9844 20.0132 18.9122 19.8475 18.8625C19.6841 18.8128 19.503 18.7879 19.3042 18.7879C18.9325 18.7879 18.6058 18.8803 18.324 19.0649C18.0447 19.2496 17.8269 19.5183 17.6706 19.871C17.5144 20.2214 17.4363 20.6499 17.4363 21.1565C17.4363 21.6632 17.5132 22.094 17.6671 22.4492C17.821 22.8043 18.0388 23.0753 18.3205 23.2624C18.6022 23.447 18.9348 23.5393 19.3184 23.5393C19.6664 23.5393 19.9635 23.4778 20.2097 23.3547C20.4583 23.2292 20.6477 23.0528 20.7779 22.8256C20.9105 22.5983 20.9767 22.3296 20.9767 22.0195L21.2892 22.0656H19.4142V20.908H22.4576V21.8242C22.4576 22.4634 22.3226 23.0126 22.0527 23.4719C21.7828 23.9288 21.4112 24.2815 20.9377 24.5301C20.4642 24.7763 19.9221 24.8994 19.3113 24.8994C18.6294 24.8994 18.0305 24.7491 17.5144 24.4484C16.9983 24.1454 16.5958 23.7157 16.307 23.1594C16.0205 22.6007 15.8773 21.9378 15.8773 21.1707C15.8773 20.5813 15.9625 20.0557 16.133 19.594C16.3058 19.13 16.5473 18.737 16.8574 18.4151C17.1676 18.0931 17.5286 17.8481 17.9405 17.68C18.3525 17.5119 18.7987 17.4278 19.2793 17.4278C19.6912 17.4278 20.0748 17.4882 20.4299 17.609C20.785 17.7273 21.0998 17.8954 21.3745 18.1132C21.6515 18.331 21.8775 18.5902 22.0527 18.8909C22.2279 19.1892 22.3404 19.5183 22.3901 19.8781H20.8276Z"
      fill="white"
    />
  </svg>
</template>
