<script setup lang="ts">
const { closeModal, openModal, setModalBusy, isVisible, isBusy } =
  useGlobalModalController()
</script>

<template>
  <div>
    <BaseModal
      title="Set up direct debit"
      size="xl"
      :show="isVisible('direct-debit-setup')"
      :can-close="!isBusy('direct-debit-setup')"
      @close-modal="closeModal('direct-debit-setup')"
    >
      <ModulesCreditFacilityAirWallexDebitSetup
        class="mt-4"
        @is-busy="(val) => setModalBusy('direct-debit-setup', val)"
        @close="closeModal('direct-debit-setup')"
        @success="
          () => {
            openModal('direct-debit-connection-successful')
            closeModal('direct-debit-setup')
          }
        "
      />
    </BaseModal>

    <BaseModal
      title="Upload required documents"
      size="xl"
      :show="isVisible('new-filed-accounts')"
      :can-close="!isBusy('new-filed-accounts')"
      @close-modal="closeModal('new-filed-accounts')"
    >
      <UploadsProfitLossUploader
        @success="
          () => {
            closeModal('new-filed-accounts')
          }
        "
      />
    </BaseModal>

    <BaseModal
      :show="isVisible('direct-debit-connection-successful')"
      :show-heading="false"
      :show-divider-between-heading-and-content="false"
      @close-modal="closeModal('direct-debit-connection-successful')"
    >
      <SharedModalsViewsDirectDebitConnectionSuccessful
        @close="closeModal('direct-debit-connection-successful')"
      />
    </BaseModal>
  </div>
</template>
