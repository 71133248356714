<script setup lang="ts">
import {
  AccordionHeader,
  AccordionTrigger,
  type AccordionTriggerProps,
} from 'radix-vue'
import { PhSpinner } from '@phosphor-icons/vue'
import { ChevronDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
type Props = AccordionTriggerProps & {
  class?: string
  showChevron?: boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showChevron: true,
  loading: false,
})
</script>

<template>
  <AccordionHeader class="flex" as="div">
    <AccordionTrigger
      v-bind="props"
      :class="
        cn(
          'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
          props.class,
        )
      "
    >
      <slot />
      <div v-if="loading">
        <PhSpinner class="h-5 w-5 shrink-0 animate-spin" />
      </div>
      <div v-else>
        <ChevronDown
          v-if="showChevron"
          class="h-4 w-4 shrink-0 transition-transform duration-200"
        />
      </div>
    </AccordionTrigger>
  </AccordionHeader>
</template>
