type ModalName =
  | 'direct-debit-setup'
  | 'direct-debit-connection-successful'
  | 'new-filed-accounts'

interface ModalState {
  isVisible: boolean
  isBusy: boolean
}

const modalStates = reactive<Record<ModalName, ModalState>>({
  'direct-debit-setup': { isVisible: false, isBusy: false },
  'new-filed-accounts': { isVisible: false, isBusy: false },
  'direct-debit-connection-successful': { isVisible: false, isBusy: false },
})

export function useGlobalModalController() {
  function setModalVisibility(modal: ModalName, isVisible: boolean) {
    modalStates[modal].isVisible = isVisible
  }

  function setModalBusy(modal: ModalName, isBusy: boolean) {
    modalStates[modal].isBusy = isBusy
  }

  function openModal(modal: ModalName) {
    setModalVisibility(modal, true)
  }

  function closeModal(modal: ModalName) {
    setModalVisibility(modal, false)
  }

  function isVisible(modal: ModalName) {
    return modalStates[modal].isVisible
  }

  function isBusy(modal: ModalName) {
    return modalStates[modal].isBusy
  }

  return {
    modalStates,
    openModal,
    closeModal,
    setModalVisibility,
    setModalBusy,
    isVisible,
    isBusy,
  }
}
