import type { CurrencyType } from '~/shared/interfaces'

const currencyMap = {
  GBP: '£',
  USD: '$',
  EUR: '€',
} as const

export const getCurrency = (currencyType: CurrencyType) => {
  if (currencyType === 'Other' || currencyType === null) return ''
  return currencyMap[currencyType]
}

export function formatMoneyWithCurrency(
  value: number,
  options: Intl.NumberFormatOptions & { locale?: string } = {},
) {
  const { locale, ...rest } = options
  return Intl.NumberFormat(locale || 'en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'GBP',
    currencySign: 'accounting',
    ...rest,
  }).format(value)
}
