<template>
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.3501 4C8.3501 2.20507 9.80517 0.75 11.6001 0.75H23.6001C23.6573 0.75 23.7122 0.772724 23.7526 0.813172L32.3869 9.44749C32.4274 9.48794 32.4501 9.5428 32.4501 9.6V28C32.4501 29.7949 30.995 31.25 29.2001 31.25H11.6001C9.80517 31.25 8.3501 29.7949 8.3501 28V4Z"
      fill="white"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <path
      d="M23.6001 0.399994V5.6C23.6001 7.80913 25.391 9.59999 27.6001 9.59999H32.8001"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <rect x="0.600098" y="12.8" width="22" height="16" rx="2" fill="#344054" />
    <path
      d="M4.18044 24.8V23.8874L7.8097 18.795H4.17334V17.5273H9.74152V18.4399L6.10871 23.5322H9.74862V24.8H4.18044ZM12.4661 17.5273V24.8H10.9285V17.5273H12.4661ZM13.7312 24.8V17.5273H16.6005C17.1522 17.5273 17.6221 17.6326 18.0103 17.8433C18.3986 18.0517 18.6945 18.3417 18.8981 18.7134C19.1041 19.0827 19.2071 19.5088 19.2071 19.9918C19.2071 20.4747 19.1029 20.9009 18.8946 21.2702C18.6862 21.6395 18.3844 21.9271 17.989 22.1331C17.596 22.3391 17.1202 22.442 16.5615 22.442H14.7326V21.2098H16.3129C16.6088 21.2098 16.8527 21.1589 17.0444 21.0571C17.2386 20.9529 17.383 20.8097 17.4777 20.6274C17.5747 20.4428 17.6233 20.2309 17.6233 19.9918C17.6233 19.7503 17.5747 19.5396 17.4777 19.3597C17.383 19.1774 17.2386 19.0365 17.0444 18.9371C16.8503 18.8353 16.6041 18.7844 16.3058 18.7844H15.2689V24.8H13.7312Z"
      fill="white"
    />
  </svg>
</template>
