import FileTypeDoc from '@/components/Icons/file-type-doc.vue'
import FileTypeImg from '@/components/Icons/file-type-img.vue'
import FileTypePdf from '@/components/Icons/file-type-pdf.vue'
import FileTypeZip from '@/components/Icons/file-type-zip.vue'
import FileTypeXls from '@/components/Icons/file-type-xls.vue'
import FileTypeCsv from '@/components/Icons/file-type-csv.vue'

export function getFileIcon(fileType: File['type']) {
  const iconMap: Record<string, unknown> = {
    'application/pdf': FileTypePdf,
    'application/msword': FileTypeDoc,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      FileTypeDoc,
    'application/vnd.ms-excel': FileTypeXls,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      FileTypeXls,
    'image/jpeg': FileTypeImg,
    'image/png': FileTypeImg,
    'image/gif': FileTypeImg,
    'application/zip': FileTypeZip,
    'application/x-zip-compressed': FileTypeZip,
    'text/csv': FileTypeCsv,
  }

  return iconMap[fileType] || null
}
