import { User } from 'oidc-client-ts'
import { useAuthStore } from '@/stores/auth'
import { useAuthService } from '@/services/auth'
import { LOCAL_STORAGE_KEYS } from '~/constants/CONSTANTS'

const authFlowRoutes = ['/auth', '/silent-refresh', '/logout', '/callback']
const excludedRoutes = [routes.quickBooksDisconnectedPage]

export default defineNuxtRouteMiddleware(async (to, _) => {
  if (excludedRoutes.includes(to.path)) return
  const authStore = useAuthStore()
  const authService = useAuthService()
  const user = (await authService.getUser()) as User

  if (!user && !authFlowRoutes.includes(to.path)) {
    const { platformId, brokerId, invitationId } = to.query
    if (brokerId) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.BROKER_ID,
        brokerId?.toString() || '',
      )
    }

    if (invitationId) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.INVITATION_ID,
        invitationId?.toString() || '',
      )
    }

    if (platformId) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.PLATFORM_ID,
        platformId?.toString() || '',
      )
    }

    // sessionStorage.setItem('redirectTo', to.fullPath.toString())
    // const redirectParams: SigninRedirectArgs | undefined = {
    //   extraQueryParams: {
    //     ...(platformId ? { platformId: platformId.toString() } : {}),
    //     ...(brokerId ? { brokerId: brokerId.toString() } : {}),
    //   },
    // }
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URI, to.fullPath)
    authService.signInRedirect()
  } else {
    authStore.setUpUserCredentials(user)
  }
})
