<template>
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.3501 4C8.3501 2.20507 9.80517 0.75 11.6001 0.75H23.6001C23.6573 0.75 23.7122 0.772724 23.7526 0.813172L32.3869 9.44749C32.4274 9.48794 32.4501 9.5428 32.4501 9.6V28C32.4501 29.7949 30.995 31.25 29.2001 31.25H11.6001C9.80517 31.25 8.3501 29.7949 8.3501 28V4Z"
      fill="white"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <path
      d="M23.6001 0.399994V5.6C23.6001 7.80913 25.391 9.59999 27.6001 9.59999H32.8001"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <rect x="0.200195" y="12.8" width="26" height="16" rx="2" fill="#D92D20" />
    <path
      d="M4.03249 24.8V17.5273H6.90181C7.45342 17.5273 7.92335 17.6326 8.31161 17.8433C8.69987 18.0517 8.9958 18.3417 9.1994 18.7134C9.40536 19.0827 9.50835 19.5088 9.50835 19.9918C9.50835 20.4747 9.40418 20.9009 9.19585 21.2702C8.98751 21.6395 8.68567 21.9271 8.29031 22.1331C7.89731 22.3391 7.42146 22.442 6.86275 22.442H5.03391V21.2098H6.61417C6.9101 21.2098 7.15394 21.1589 7.3457 21.0571C7.53983 20.9529 7.68424 20.8097 7.77894 20.6274C7.87601 20.4428 7.92454 20.2309 7.92454 19.9918C7.92454 19.7503 7.87601 19.5396 7.77894 19.3597C7.68424 19.1774 7.53983 19.0365 7.3457 18.9371C7.15157 18.8353 6.90536 18.7844 6.60707 18.7844H5.57013V24.8H4.03249ZM13.0852 24.8H10.5071V17.5273H13.1065C13.8381 17.5273 14.4678 17.6729 14.9957 17.9641C15.5237 18.2529 15.9297 18.6684 16.2138 19.2105C16.5002 19.7527 16.6435 20.4013 16.6435 21.1565C16.6435 21.9141 16.5002 22.5652 16.2138 23.1097C15.9297 23.6542 15.5213 24.072 14.9886 24.3632C14.4583 24.6544 13.8239 24.8 13.0852 24.8ZM12.0447 23.4825H13.0213C13.4759 23.4825 13.8582 23.402 14.1683 23.2411C14.4808 23.0777 14.7152 22.8256 14.8714 22.4847C15.0301 22.1414 15.1094 21.6987 15.1094 21.1565C15.1094 20.6191 15.0301 20.18 14.8714 19.8391C14.7152 19.4982 14.482 19.2472 14.1719 19.0862C13.8617 18.9252 13.4794 18.8447 13.0249 18.8447H12.0447V23.4825ZM17.7825 24.8V17.5273H22.5978V18.795H19.3201V20.528H22.2782V21.7957H19.3201V24.8H17.7825Z"
      fill="white"
    />
  </svg>
</template>
