<script setup lang="ts">
import { PhSpinner, PhTrash } from '@phosphor-icons/vue'
import useVuelidate from '@vuelidate/core'
import { ChevronRight } from 'lucide-vue-next'
import { helpers } from '@vuelidate/validators'
import { toast } from '../ui/toast'
import { isLastItem, vuelidateErrorMessage } from '~/lib/utils'
import { getFileIcon } from '~/lib/file-utils'

interface Emits {
  (e: 'success'): void
}

const emit = defineEmits<Emits>()

const { organisationId } = storeToRefs(useProfileStore())

const defaultFormState = {
  filedAccountFiles: [] as File[],
  additionalDocumentFiles: [] as File[],
}

type ReferralFormShape = typeof defaultFormState

type FileKeys = keyof Pick<
  ReferralFormShape,
  'additionalDocumentFiles' | 'filedAccountFiles'
>

const atLeastOneFile = helpers.withParams<File[]>(
  { type: 'atLeastOneFile' },
  (value) => value.length > 0,
)

const acceptableFileTypes =
  'application/pdf, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, text/csv'

const rules: Record<keyof ReferralFormShape, object> = {
  filedAccountFiles: {
    required: helpers.withMessage('Upload at least one file', atLeastOneFile),
  },
  additionalDocumentFiles: {},
}

const formData = reactive({ ...defaultFormState })
const v$ = useVuelidate(rules, formData)

const isUploading = ref(false)
const activeFileTab = ref<FileKeys>()
const tabsForFile: { name: string; key: FileKeys }[] = [
  { name: 'Latest filed accounts', key: 'filedAccountFiles' },
  {
    name: 'Any other relevant financial document',
    key: 'additionalDocumentFiles',
  },
]

function handleRejections() {}

function addFiles(files: File[], to: FileKeys) {
  const key = to
  if (key) {
    formData[key] = [...formData[key], ...files]
  }
  activeFileTab.value = to
}

function removeFile(index: number, from: FileKeys) {
  const key = from
  formData[key].splice(index, 1)
}

const { $api, $event } = useNuxtApp()
const { upload } = useFileUploader()

async function handleSubmit() {
  v$.value.$touch()

  if (v$.value.$invalid) return

  if (!organisationId.value) return

  const { additionalDocumentFiles, filedAccountFiles } = formData

  isUploading.value = true
  const uploadPromises = [
    upload({
      organisationId: organisationId.value,
      files: filedAccountFiles,
      uploadType: 'broker-files',
    }),
    ...(additionalDocumentFiles.length > 0
      ? [
          upload({
            organisationId: organisationId.value,
            files: additionalDocumentFiles,
            uploadType: 'broker-files',
          }),
        ]
      : []),
  ]

  const [filedAccountFilesUrls, additionalDocumentFilesUrl] =
    await Promise.all(uploadPromises)

  // Check for filed account files upload
  if (!filedAccountFilesUrls) {
    throw new Error('Filed account file upload failed')
  }

  // Check for additional document files upload
  if (
    additionalDocumentFilesUrl &&
    !Array.isArray(additionalDocumentFilesUrl)
  ) {
    throw new Error('Additional document file upload failed')
  }

  try {
    const response = await $api.core.organisations.updateOrganisationDocuments(
      organisationId.value,
      {
        filed_accounts_url: filedAccountFilesUrls.join(','),
        ...(additionalDocumentFilesUrl && {
          other_documents_url: additionalDocumentFilesUrl.join(','),
        }),
      },
    )

    if (response.status === 200) {
      toast({
        title: 'File(s) uploaded successfully!',
        description: 'Refreshing your dashboard...',
      })
      emit('success')
      $event('update:credit-facility', null)
    }
  } catch (_error) {
    toast({
      title: 'Error occurred',
      description: 'Error occurred while uploading documents',
    })
  } finally {
    isUploading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div :class="isUploading ? 'pointer-events-none opacity-60' : ''">
      <Accordion
        v-model="activeFileTab"
        type="single"
        class="w-full"
        collapsible
      >
        <AccordionItem
          v-for="(tab, idx) in tabsForFile"
          :key="tab.key"
          :value="tab.key"
          :class="`${isLastItem(tabsForFile.length, idx) ? 'border-0' : ''} py-3`"
        >
          <div>
            <div class="flex items-center justify-between">
              <AccordionTrigger
                :show-chevron="false"
                class="[&[data-state=open]>svg]:rotate-90"
              >
                <ChevronRight class="text-zinc-500" />
                <PhSpinner
                  v-if="isUploading"
                  size="20"
                  class="animate-spin text-zinc-500"
                />
                <div class="ml-3 text-base text-primary">
                  {{ tab.name }}
                  <span v-if="formData[tab.key].length"
                    >({{ formData[tab.key].length }})</span
                  >
                </div>
              </AccordionTrigger>

              <UploadsFileUploader
                id="filedDocuments"
                :accept="acceptableFileTypes"
                :multiple="true"
                variant="button"
                @files="(val) => addFiles(val, tab.key)"
                @rejections="handleRejections"
              >
                <Button
                  :disabled="isUploading"
                  variant="outline"
                  size="sm"
                  type="button"
                  >{{
                    formData[tab.key].length ? 'Add file(s)' : 'Upload file(s)'
                  }}</Button
                >
              </UploadsFileUploader>
            </div>

            <p
              v-if="v$[tab.key].$error"
              class="text-center text-sm text-red-500"
            >
              {{ vuelidateErrorMessage(v$[tab.key].$errors) }}
            </p>
          </div>

          <AccordionContent class="">
            <div v-if="formData[tab.key].length" class="space-y-2">
              <div v-for="(file, jdx) in formData[tab.key]" :key="jdx">
                <!-- <FileUploadCard :file="file" /> -->

                <div class="flex w-full items-center">
                  <component
                    :is="getFileIcon(file.type)"
                    v-if="getFileIcon(file.type)"
                    class="mr-3"
                  />

                  <p
                    class="max-w-36 truncate text-sm font-medium text-primary first-letter:uppercase lg:max-w-56"
                  >
                    {{ file.name }}
                  </p>
                  <!-- <size
                        class="mx-2 size-1 rounded-full bg-[#767676]"
                      ></size>
                      <button
                        type="button"
                        class="text border-none text-sm text-[#677EB7] outline-none hover:underline"
                      >
                        Preview
                      </button> -->
                  <Button
                    class="ml-auto size-6"
                    size="icon"
                    variant="destructive"
                    type="button"
                    @click="removeFile(jdx, tab.key)"
                    ><PhTrash :size="20" />
                  </Button>
                </div>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
    <div class="mt-5">
      <Button :disabled="isUploading" class="w-full">
        <PhSpinner v-if="isUploading" size="20" class="mr-3 animate-spin" />
        Submit documents</Button
      >
    </div>
  </form>
</template>
