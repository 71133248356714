import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import type { MembersResult } from '~/shared/interfaces'
import type {
  GetMeResponseV2,
  Onboarding,
} from '~/types/apiResponse/me.response'
import type { IDirector, IOrganisation } from '~/services'
import type { PossibleMandateStatus } from '~/types/apiResponse/payments.response'

type SingleOrganisation = GetMeResponseV2['organisations'][number]

interface DefaultStateType {
  data: { organisation?: IOrganisation; [key: string]: any }
  members: MembersResult | {}
  fetched: boolean
  fetchMember: boolean
  profileLoad: boolean
  profile: GetMeResponseV2 | null
  error: any
  isGNPLUser: boolean
  userHasOnboardedCompletely: boolean
  mandateStatus: PossibleMandateStatus | null
  organisationId: string | null
}

const defaultState: () => DefaultStateType = () => ({
  data: {},
  members: {},
  fetched: false,
  profileLoad: false,
  profile: null,
  error: {},
  fetchMember: false,
  isGNPLUser: false,
  mandateStatus: null,
  userHasOnboardedCompletely: false,
  organisationId: null,
})

export const useProfileStore = defineStore('profile', {
  state: defaultState,

  actions: {
    setIsGNPLUser(value: boolean) {
      this.isGNPLUser = value
    },
    fetchAuthAndApiData() {
      const { $lenkieApi, $lenkieBankingApi, $lenkieCompositionApi } =
        useNuxtApp()
      return { $lenkieApi, $lenkieBankingApi, $lenkieCompositionApi }
    },
    async userProfile() {
      try {
        const { $api } = useNuxtApp()
        const { data } = await $api.composition.me.getMeV2()
        this.profile = data
        this.profileLoad = true
      } catch (error: any) {
        this.profileLoad = true
        this.error = error
      } finally {
        this.profileLoad = true
      }
    },

    resetProfile() {
      this.data = {}
      this.members = []
      this.fetched = false
    },
    async setOrganisation() {
      const { $api } = useNuxtApp()
      if (!this.organisationId) throw new Error('No OrganisationId')

      try {
        const { data: organisation } =
          await $api.core.organisations.getSpecificOrganisation(
            this.organisationId,
          )
        this.data = {
          ...this.data,
          sellerId: organisation?.id,
          organisation,
        }
        this.fetched = true

        return organisation
      } catch (error: any) {
        this.fetched = true
      } finally {
        this.fetched = true
        // await connectionStore.setOpenBankingCount()
        // await connectionStore.setEcommerceCount()
        // await connectionStore.setPaymentsCount()
      }
    },
    async getMember() {
      try {
        await this.setOrganisation()
        const organizationId = this.organisationId
        const { personId } = useAuthStore()
        const { $lenkieApi } = useNuxtApp()
        const { data } = await $lenkieApi.get(`invitations`, {
          params: { organisationId: organizationId, personId },
        })
        if (data.is_success) {
          this.members = data
        } else {
          this.data = []
        }
      } catch (error) {
        this.error = error
      } finally {
        this.fetchMember = true
      }
    },
    setOrgId(orgId: string) {
      this.organisationId = orgId
    },
  },
  getters: {
    // isOnboardingComplete: (state) => state.userHasOnboardedCompletelyx,
    failedPrequalification: (state) =>
      state.data?.organisation?.failed_pre_qualification === true,
    allMyOrganisations: (state) => state.profile?.organisations || [],
    currentlySelectedOrganisation(state): SingleOrganisation | null {
      const org = this.allMyOrganisations.find(
        (org) => org.id === state.organisationId,
      )
      return org || null
    },
    currentlySelectedOrganisationOnboarding(): Onboarding | null {
      return this.currentlySelectedOrganisation?.onboarding || null
    },
    isOnboardingComplete(): boolean {
      return (
        this.currentlySelectedOrganisationOnboarding?.status?.id === 'Completed'
      )
    },
    allDirectors(state) {
      return state.data?.organisation?.directors || []
    },
    verifiedDirectors(): IDirector[] {
      return this.allDirectors.filter(
        (director) =>
          director.verification &&
          director.verification.status.id === 'Approved',
      )
    },
    nonVerifiedDirectors(): IDirector[] {
      return this.allDirectors.filter(
        (director) =>
          !director.verification ||
          director.verification.status.id !== 'Approved',
      )
    },
    organisationContract(state) {
      return state.data?.organisation?.contract
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
