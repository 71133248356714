import { useAuthStore } from '~/stores/auth'
import { useProfileStore } from '~/stores/profile' // Assuming you have a profile store

const routesToSkipOnboardingCheck = [
  '/settings/connections',
  '/settings/business',
  '/auth',
  '/silent-refresh',
  '/logout',
  '/callback',
  '/callback/open-banking',
  '/organisation/create',
  '/organisation/select',
  '/user/invitations',
  routes.profile,
  routes.facilityDenied,
]

const onboardingBaseRoute = '/onboarding'

function normalizedPath(path: string) {
  return path.replace(/\/+$/, '')
}

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    utils: {
      getOrganisationId,
      setProfile,
      //  setOrganisationId
    },
  } = useUserFlow()
  const { $api } = useNuxtApp()

  // Ensure middleware runs only on the client side
  if (!import.meta.client) {
    return
  }

  // Get authentication status
  const { isLoggedIn } = storeToRefs(useAuthStore())
  if (!isLoggedIn.value) return

  // Normalize the target path
  const normalizedToPath = normalizedPath(to.path)

  // Skip onboarding check for specific routes
  if (
    routesToSkipOnboardingCheck.includes(normalizedToPath) ||
    normalizedToPath.startsWith('/callback')
  ) {
    return
  }

  const profileStore = useProfileStore()
  const {
    currentlySelectedOrganisationOnboarding,
    // currentlySelectedOrganisation,
  } = storeToRefs(profileStore)

  let isOnboardingComplete =
    currentlySelectedOrganisationOnboarding.value?.status?.id === 'Completed'

  // Fetch onboarding status if not completed locally to make sure local is correctly updated
  if (!isOnboardingComplete) {
    try {
      const { data, status } = await $api.composition.me.getMeV2()
      if (status === 200) {
        // Update local state
        setProfile(data)
        const organisations = data.organisations

        if (organisations.length === 0) {
          if (!normalizedToPath.startsWith(onboardingBaseRoute)) {
            return navigateTo(routes.onboarding)
          }
          return
        }

        if (organisations.length === 1) {
          const org = organisations[0]

          isOnboardingComplete = org.onboarding.status.id === 'Completed'
          if (isOnboardingComplete) {
            if (normalizedToPath.startsWith(onboardingBaseRoute)) {
              return navigateTo(routes.dashboard)
            }
          } else {
            if (!normalizedToPath.startsWith(onboardingBaseRoute)) {
              return navigateTo(routes.onboarding)
            }
            return
          }

          return
        }

        if (organisations.length > 1) {
          const lastUsedOrgId = getOrganisationId('localstorage')
          const isValid = organisations.some((org) => org.id === lastUsedOrgId)

          if (isValid) {
            const currentOrg = organisations.find(
              (org) => org.id === lastUsedOrgId,
            )

            isOnboardingComplete =
              currentOrg?.onboarding.status.id === 'Completed'

            if (isOnboardingComplete) {
              if (normalizedToPath.startsWith(onboardingBaseRoute)) {
                return navigateTo(routes.dashboard)
              }
            } else {
              if (!normalizedToPath.startsWith(onboardingBaseRoute)) {
                return navigateTo(routes.onboarding)
              }
              return
            }
          } else {
            return navigateTo('/user/invitations')
          }
        }

        // if (!lastUsedOrgId) return navigateTo('/organisation/select')

        // Redirect to dashboard if onboarding is complete and trying to access any onboarding route
        if (normalizedToPath.startsWith(onboardingBaseRoute)) {
          return navigateTo(routes.dashboard)
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return navigateTo(routes.profile, { replace: true })
      }
    }
  }

  // Allow access to the exact onboarding route if onboarding is not complete
  // Redirect to the main onboarding route if not complete and not on an onboarding route
  // if (!isOnboardingComplete) {
  //   if (!normalizedToPath.startsWith(onboardingBaseRoute)) {
  //     return navigateTo({ path: onboardingBaseRoute })
  //   }
  // }
})
