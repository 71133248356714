<script setup lang="ts">
import {
  createElement,
  loadAirwallex,
  type ElementEvent,
} from 'airwallex-payment-elements'
import { toast } from '~/components/ui/toast'
import { useAuthStore } from '~/stores/auth'
import { markdownifyObject } from '~/lib/utils'
const { organisationId, currentlySelectedOrganisation } =
  storeToRefs(useProfileStore())
const { authUser, personId } = storeToRefs(useAuthStore())
const { $api, $event } = useNuxtApp()
interface Emits {
  (e: 'isBusy', v: boolean): void
  (e: 'success'): void
  (e: 'close'): void
}
const emit = defineEmits<Emits>()
const errorMessage = ref<string>()
function generateErrorForBugSnagReport(error: object) {
  if (!currentlySelectedOrganisation.value || !authUser.value) return
  const {
    trading_name,
    registered_name,
    id: orgId,
  } = currentlySelectedOrganisation.value
  const { email, family_name, given_name } = authUser.value.profile
  return `
  *Direct Debit Error*

    *User Details*
    - *Email*: ${email}
    - *Name*: ${given_name} ${family_name}
    - *Person Id*: ${personId.value}

    *Company Details*
    - *Name*: ${trading_name || registered_name}
    - *Company Id*: ${orgId}

  \n
*Error Content*
${markdownifyObject(error)}
  \n
  `
}

const {
  $config: {
    public: { APP_ENV },
  },
} = useNuxtApp()
async function init() {
  if (!organisationId.value) return
  const reponse =
    await $api.banking.repayments.generateAirwallexClientSecretData(
      organisationId.value,
    )
  if (reponse.status !== 200) return
  const { id, client_secret } = reponse.data
  loadAirwallex({
    env: APP_ENV === 'production' ? 'prod' : 'demo',
    origin: window.location.origin,
  }).then(() => {
    const element = createElement('dropIn', {
      customer_id: id,
      client_secret,
      shopper_name:
        currentlySelectedOrganisation.value?.registered_name ||
        currentlySelectedOrganisation.value?.trading_name,
      currency: 'GBP',
      mode: 'recurring',
      methods: ['bacs_direct_debit'],
      recurringOptions: {
        next_triggered_by: 'merchant',
        currency: 'GBP',
      },
      theme: {
        palette: {
          primary: '#1a2d5b',
        },
      },
    })
    if (element) {
      element.mount('dropIn')
    }
  })
}
function onSuccess(_event: Event & ElementEvent) {
  $event('update:credit-facility', null)
  emit('success')
}
function onReady(_event: Event & ElementEvent) {
  // console.log({ event })
  /**
   * ... Handle event on element mount
   */
  // document.getElementById('loading').style.display = 'none' // Example: hide loading state when element is mounted
  // document.getElementById('dropIn').style.display = 'block' // Example: show element when mounted
  // console.log(`Element is mounted: ${JSON.stringify(event.detail)}`)
}
function onError(event: Event & ElementEvent) {
  /**
   * ... Handle event on error
   */
  const { error } = event.detail
  // eslint-disable-next-line no-console
  console.error('There was an error', error)
  errorMessage.value = error?.message
  toast({ title: errorMessage.value })
  if (error) {
    $event('error:report', new Error(generateErrorForBugSnagReport(error)))
  }
}
function onPendingVerifyAccount(event: Event & ElementEvent) {
  // eslint-disable-next-line no-console
  console.log(event, 'verification')
  toast({
    title: 'Verification needed',
    description:
      'Verify direct debit. Please check your email for the next steps to verify your direct debit mandate.',
    duration: 10_000,
  })
  $event('update:credit-facility', null)
  emit('close')
  // emit('success')
}
// watchImmediate(
//   organisationId,
//   async (newVal) => {
//     if (!newVal) return
//     await init()
//     const domElement = document.getElementById('dropIn')
//     if (domElement) {
//       domElement.addEventListener('onReady', onReady)
//       domElement.addEventListener('onSuccess', onSuccess)
//       domElement.addEventListener('onError', onError)
//     }
//   },
//   { flush: 'post' },
// )
onMounted(() => {
  init()
  const domElement = document.getElementById('dropIn')
  if (domElement) {
    domElement.addEventListener('onReady', onReady)
    domElement.addEventListener('onSuccess', onSuccess)
    domElement.addEventListener('onError', onError)
    domElement.addEventListener(
      'onPendingVerifyAccount',
      onPendingVerifyAccount,
    )
  }
})
onBeforeUnmount(() => {
  const domElement = document.getElementById('dropIn')
  // Be sure to clean up event listeners when component unmounts
  if (domElement) {
    domElement.removeEventListener('onReady', onReady)
    domElement.removeEventListener('onSuccess', onSuccess)
    domElement.removeEventListener('onError', onError)
    domElement.removeEventListener(
      'onPendingVerifyAccount',
      onPendingVerifyAccount,
    )
  }
})
</script>

<template>
  <div>
    <div v-if="errorMessage" class="space-y-3 py-4 text-center">
      <p class="text-xl">{{ errorMessage }}</p>
      <Button class="" @click="emit('close')">Dismiss</Button>
    </div>
    <div v-else id="dropIn"></div>
  </div>
</template>
